import { ItemStorage } from '@grandstand-web/bally-web-shared/src/newPackages/StorageProviders/localStorageProvider'
import getConfig from 'next/config'
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useState } from 'react'
import { PlayerType } from '../types'
export type PlayerSharedValue = {
  playerType: PlayerType
  setPlayerType: Dispatch<SetStateAction<PlayerType>>
  isShaka: boolean
  isBitmovin: boolean
}

export const PlayerSharedContext = createContext<PlayerSharedValue>({
  playerType: PlayerType.Shaka,
  setPlayerType: () => {},
  isShaka: true,
  isBitmovin: false,
})
const playerTypeStorage = new ItemStorage<PlayerType | undefined>('PLAYER_TYPE', undefined)

export const PlayerSharedProvider = ({ children }: PropsWithChildren) => {
  const [playerType, setPlayerTypeValue] = useState<PlayerType>(getPlayerType())
  const isShaka = playerType === PlayerType.Shaka
  const isBitmovin = playerType === PlayerType.Bitmovin
  useEffect(() => {
    setPlayerTypeValue(getPlayerType())
  }, [])

  const setPlayerType: Dispatch<SetStateAction<PlayerType>> = (valueOrGetValue) => {
    setPlayerTypeValue((prev) => {
      let value = prev
      try {
        value = typeof valueOrGetValue === 'function' ? valueOrGetValue(prev) : valueOrGetValue
      } catch (_) {
        // ignore
      }
      playerTypeStorage.setItem(value)
      return value
    })
  }

  return (
    <PlayerSharedContext.Provider
      value={{
        playerType,
        setPlayerType,
        isShaka,
        isBitmovin,
      }}
    >
      {children}
    </PlayerSharedContext.Provider>
  )
}

export const usePlayerShared = () => {
  const ctx = useContext(PlayerSharedContext)
  return ctx
}

export function getPlayerType(): PlayerType {
  const saved = playerTypeStorage.getItem()
  if (saved) {
    return saved
  }
  return getConfigPlayerType()
}

function getConfigPlayerType(): PlayerType {
  const { publicRuntimeConfig } = getConfig()
  if (typeof publicRuntimeConfig?.playerType === 'string') {
    return publicRuntimeConfig.playerType === PlayerType.Bitmovin ? PlayerType.Bitmovin : PlayerType.Shaka
  }
  return PlayerType.Shaka
}
