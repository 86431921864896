import { clientRuntimeConfig } from '../config'

enum environments {
  dev = 'dev',
  stage = 'stage',
  prod = 'prod',
}

type webHost = 'fanduelsportsnetwork.com' | 'stage.fanduelsportsnetwork.com' | 'dev.fanduelsportsnetwork.com'

export const getCodeEnvironment = (): environments => {
  switch (clientRuntimeConfig.environment) {
    case 0:
      return environments.prod
    case 6:
      return environments.stage
    default:
      return environments.dev
  }
}

export const isNotInProd = (): boolean => getCodeEnvironment() !== environments.prod

// Note that for many cases, the full display url is provided by the middleware.
// This is a workaround for when we don't really need a full URL or QRCode
export const getWebHostName = (): webHost => {
  const environment = getCodeEnvironment()
  switch (environment) {
    case environments.prod:
      return 'fanduelsportsnetwork.com'
    case environments.stage:
      return 'stage.fanduelsportsnetwork.com'
    default:
      return 'dev.fanduelsportsnetwork.com'
  }
}
